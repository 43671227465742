<template lang="pug">
.om-onboarding-ecommerce-welcome.mb-6.mb-md-0
  wizard-top(:show-progress="false")
  .container(:style="'margin-top: 2.75rem'")
    .row
      .d-none.d-lg-flex.col-lg-1
      .col-3
        om-monks.mb-4.mb-md-0(monk="hi-user2")
      .col-9.col-lg-6.pb-5(:style="{ 'padding-right': '4.25rem' }")
        .welcome-input-container
          wizard-title.mb-md-3.text-center.text-md-left.mb-6 {{ $t('onboarding.welcome.title') }}
          om-body-text.mb-4.pb-2 {{ $t('onboarding.welcome.text1') }}
        .welcome-input-container
          .form-group.mb-2.business-name-form-group
            om-input#businessName(
              block
              v-model="businessName"
              :label="$t('onboarding.welcome.whatBusiness')"
            )
              template(slot="error" v-if="$v.businessName.$error && !$v.businessName.required")
                span {{ $t('requiredField') }}
                  .form-group.mb-2.business-name-form-group
          .form-group.mb-2.business-domain-group(v-if="showDomainInput")
            om-input#businessDomain(
              block
              v-model="domain"
              :label="$t('onboarding.welcome.whatDomain')"
            )
              template(slot="error" v-if="$v.domain.$error && !$v.domain.required")
                span {{ $t('requiredField') }}
              template(slot="error" v-if="$v.domain.$error && !$v.domain.validUrl")
                span {{ $t('invalidUrl.default') }}
          .form-group.mb-2.business-name-form-group(v-if="showPhoneNumber")
            om-input#phoneNumber(
              block
              v-model="phoneNumber"
              prefix="+36"
              phonePrefix
              :label="$t('onboarding.welcome.whatPhoneNumber')"
            )
              template(slot="error" v-if="$v.phoneNumber.$error && !$v.phoneNumber.required")
                span {{ $t('requiredField') }}
              template(slot="error" v-if="$v.phoneNumber.$error && !$v.phoneNumber.isPhoneNumber")
                span {{ $t('notValidFormat') }}
          template(v-if="showNameInputs")
            .form-group.mb-2
              .row
                .col
                  om-input#firstName(
                    block
                    v-model="firstName"
                    :label="$t('onboarding.welcome.firstName')"
                  )
                    template(slot="error" v-if="$v.firstName.$error && !$v.firstName.required")
                      span {{ $t('requiredField') }}
                .col
                  om-input#lastName(
                    block
                    v-model="lastName"
                    :label="$t('onboarding.welcome.lastName')"
                  )
                    template(slot="error" v-if="$v.lastName.$error && !$v.lastName.required")
                      span {{ $t('requiredField') }}
            .form-group
              om-input#email(
                block
                v-model="email"
                type="email"
                :label="$t('onboarding.welcome.email')"
              )
                template(slot="error" v-if="$v.email.$error && !$v.email.required")
                  span {{ $t('requiredField') }}
                template(slot="error" v-if="$v.email.$error && !$v.email.isCoolEmail")
                  span {{ $t('invalidEmail') }}
                template(slot="error" v-if="hasEmailError")
                  span {{ $t('changeEmail.emailAlreadyExists') }}
              label.email-info.col-12.d-flex.align-items-center.font-size-0--75.font-weight-400.mt-1.mb-0.pl-0 {{ $t('registration.emailInfo') }}
          .form-check.mb-0
            input#agency.form-check-input(type="checkbox" v-model="isAgency")
            label.form-check-label.mb-0(for="agency") {{ $t('onboarding.welcome.agencyCheckboxLabel') }}
          .form-group.mt-5
            om-button(primary @click="saveData()" :loading="nextIsLoading") {{ $t('onboarding.welcome.btn') }}
      .d-none.d-lg-flex.col-lg-2
</template>
<script>
  import SAVE_EMAIL from '@/graphql/SaveEmail.gql';
  import UPDATE_LOGIN from '@/graphql/UpdateLogin.gql';
  import UPDATE_BUSINESS_NAME_AND_PHONE_NUMBER from '@/graphql/UpdateBusinessNameAndPhoneNumber.gql';
  import SAVE_AGENCY_POTENTIAL from '@/graphql/SaveAgencyPotential.gql';
  import { required, email, requiredIf } from 'vuelidate/lib/validators';
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import {
    validateDomain,
    removeProtocolFromDomain,
    removeWWWFromDomain,
    getPureDomain,
  } from '@/util';
  import WizardTop from '@/components/Wizard/Top.vue';
  import WizardTitle from '@/components/Wizard/Title.vue';
  import { ERROR_MESSAGE_KEY } from '@/utils/constant';
  import { keyInErrors } from '@/utils/error';
  import { track } from '@/services/xray';
  import { apolloClient } from '@/apollo';
  import ADD_DOMAIN from '@/graphql/AddDomain.gql';
  import navigationMixin from '../accountSetup-navigation';

  import 'intl-tel-input/build/js/utils';
  import 'intl-tel-input/build/css/intlTelInput.css';

  export default {
    components: { WizardTop, WizardTitle },
    mixins: [navigationMixin],
    data() {
      return {
        nextIsLoading: false,
        businessName: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        email: '',
        domain: '',
        originalEmail: '',
        hasEmailError: false,
        hasDomainError: false,
        comingBack: false,
        hasValidName: true,
        isAgency: false,
      };
    },
    computed: {
      ...mapGetters(['hasShopify', 'getLocale']),
      ...mapState(['account']),
      isHungarianAccount() {
        return this.getLocale === 'hu';
      },

      showNameInputs() {
        return this.hasShopify || !this.hasValidName;
      },

      showPhoneNumber() {
        return this.isHungarianAccount && !this.getPhoneNumber;
      },

      showDomainInput() {
        return !this.account.settings?.domains.length;
      },

      getPhoneNumber() {
        return this.account.login.phoneNumber || this.account.settings.onboarding.additional.phone;
      },
    },
    watch: {
      email() {
        this.hasEmailError = false;
      },
    },
    validations: {
      businessName: {
        required,
      },
      phoneNumber: {
        required: requiredIf(function () {
          return this.showPhoneNumber;
        }),
        isPhoneNumber(v) {
          if (!this.showPhoneNumber) {
            return true;
          }
          const match = /^\+?(1|[2-9][0-9])\d{6,7}$/.test(v);

          return match;
        },
      },
      domain: {
        required: requiredIf(function () {
          return this.showDomainInput && this.isHungarianAccount;
        }),
        validUrl(value) {
          if (!value || !this.showDomainInput) {
            return true;
          }
          const domain = getPureDomain(value);
          if (!domain) {
            return false;
          }
          return validateDomain(domain);
        },
      },

      email: {
        required: requiredIf(function () {
          return this.showNameInputs;
        }),
        isCoolEmail(v) {
          if (!this.showNameInputs) return true;

          return email(v.toLowerCase());
        },
      },
      firstName: {
        required: requiredIf(function () {
          return this.showNameInputs;
        }),
      },
      lastName: {
        required: requiredIf(function () {
          return this.showNameInputs;
        }),
      },
    },
    mounted() {
      this.initFormData();
    },
    methods: {
      ...mapMutations(['flagAccountAsAgency']),
      initFormData() {
        this.firstName = !this.hasShopify ? this.account.login.firstName : '';
        this.lastName = !this.hasShopify ? this.account.login.lastName : '';
        this.email = '';

        this.originalEmail = this.account.login?.emailChange?.to ?? this.account.login.email;
        this.businessName = this.account.businessName;
        this.phoneNumber = this.getClearedPhoneNumber();
        this.hasValidName = this.firstName && this.lastName;
      },
      getClearedPhoneNumber() {
        const phoneNumber =
          this.$i18n.locale === 'hu' && this.getPhoneNumber
            ? this.getPhoneNumber.split('+36')[1]
            : '';
        return phoneNumber.replace(/\s/g, '');
      },
      async saveEmail() {
        if (this.originalEmail.toLowerCase() === this.email.toLowerCase()) {
          return;
        }

        try {
          const result = await this.$apollo.mutate({
            mutation: SAVE_EMAIL,
            variables: {
              newEmail: this.email.toLowerCase(),
              originalShopifyEmail: this.originalEmail.toLowerCase(),
            },
          });
          const { errors } = result;
          if (errors?.length) {
            if (keyInErrors(ERROR_MESSAGE_KEY.EMAIL_ALREADY_REGISTERED, errors)) {
              this.hasEmailError = true;
              this.$notify({
                type: 'error',
                text: this.$t('changeEmail.emailAlreadyExists'),
              });
            } else if (keyInErrors(ERROR_MESSAGE_KEY.RATE_LIMIT, errors)) {
              this.$notify({
                type: 'error',
                text: this.$t('changeEmail.tryAgainLater'),
              });
            } else {
              this.$notify({
                type: 'error',
                text: this.$t('changeEmail.saveError'),
              });
            }
            throw new Error('EMAIL_SAVE_ERROR');
          } else {
            this.hasEmailError = false;
          }
        } catch (e) {
          console.error('Error during change email', e);
          this.nextIsLoading = false;
          if (e.message === 'EMAIL_SAVE_ERROR') {
            throw e;
          }
        }
      },
      async redirectToNextPage() {
        if (this.hasShopify && this.hasEmailError) {
          return;
        }
        await this.navigateToNext({});
      },
      async updateBusinessNameAndPhoneNumber() {
        let variables = {
          businessName: this.businessName,
        };

        if (this.showPhoneNumber) {
          variables = {
            ...variables,
            phoneNumber: `+36${this.phoneNumber}`,
          };
        }

        await this.$apollo
          .mutate({
            mutation: UPDATE_BUSINESS_NAME_AND_PHONE_NUMBER,
            variables,
          })
          .then(() => {})
          .catch(() => {
            this.nextIsLoading = false;
            this.$notify({
              type: 'error',
              title: this.$t('notifications.saveError'),
            });
          });
      },
      async updateLogin() {
        await this.$apollo
          .mutate({
            mutation: UPDATE_LOGIN,
            variables: {
              input: {
                firstName: this.firstName,
                lastName: this.lastName,
              },
            },
          })
          .then(() => {})
          .catch(() => {
            this.nextIsLoading = false;
            this.$notify({
              type: 'error',
              title: this.$t('notifications.saveError'),
            });
          });
      },
      async saveData() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        const eventTrack = this.hasShopify
          ? 'ShopifyEn'
          : this.isHungarianAccount
          ? 'NonShopifyHu'
          : 'NonShopifyEn';
        track(`accountSetup-welcome${eventTrack}`, null);
        this.$v.$reset();
        try {
          await this.updateAccountData();
          await this.redirectToNextPage();
        } catch {}
      },

      async saveDomain() {
        try {
          let domain = getPureDomain(this.domain);
          domain = removeProtocolFromDomain(this.domain);
          domain = removeWWWFromDomain(domain);
          await apolloClient.query({
            query: ADD_DOMAIN,
            variables: { domain, scrape: true },
          });
          return true;
        } catch (_) {
          return false;
        }
      },

      async saveAgencyPotential() {
        try {
          await this.$apollo
            .mutate({
              mutation: SAVE_AGENCY_POTENTIAL,
            })
            .catch(() => {
              this.nextIsLoading = false;
              this.$notify({
                type: 'error',
                title: this.$t('notifications.saveError'),
              });
            });

          this.flagAccountAsAgency();

          return true;
        } catch (_) {
          return false;
        }
      },

      async updateAccountData() {
        this.nextIsLoading = true;
        const saveDomainIfNecessary =
          this.domain && this.showDomainInput ? [this.saveDomain()] : [];
        const saveNamesIfNecessary =
          this.hasShopify || !this.hasValidName ? [this.updateLogin()] : [];
        const flagAccountAsAgencyIfNecessary = this.isAgency ? [this.saveAgencyPotential()] : [];

        try {
          if (this.hasShopify) {
            await this.saveEmail();
          }
          await Promise.all([
            this.updateBusinessNameAndPhoneNumber(),
            ...saveNamesIfNecessary,
            ...saveDomainIfNecessary,
            ...flagAccountAsAgencyIfNecessary,
          ]);

          this.nextIsLoading = false;
        } catch (e) {
          this.nextIsLoading = false;
          if (e.message === 'EMAIL_SAVE_ERROR') {
            throw e;
          }
        }
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .welcome-input-container
    width: min(100%, 34.75rem)

  .brand-onboarding-logout
      display: none

  .business-name-form-group
    .input-label
      font-size: 12px
      color: #505763
    .is-invalid
      color: #E4252D
      font-size: 12px
    .form-label
      font-size: 0.75rem

    .input-wrapper-phone
      .is-invalid
        display: block

  .input-wrapper-phone
    .input-element-wrapper
      border: none !important
  .email-info
    color: $om-gray-600
</style>
